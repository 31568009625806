import React, { useEffect, useRef, useState, useContext, memo } from 'react';
import DomainContext from "Context/DomainContext";
import MainCategories from "Components/Product/ProductListing/MainCategories";
import SubCategories from "Components/Product/ProductListing/SubCategories";
import ProductDetails from 'Components/Product/ProductDetails';
import { useLocation, useNavigate } from 'react-router-dom';
import './styles.scss';
import { APIQueryGet } from "APIMethods/API";

const ProductPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { storeId,defaultURL} = useContext(DomainContext);
    const APIReference = useRef(false);
    const [checkUrlType, setCheckUrlType] = useState({});
    const prevPathNameRef = useRef();
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if(!location?.state?.typeCheckValue){
            const path = location?.pathname;
            if(path === "/patch-embleem-geweven") {
                setCheckUrlType({entityType:"product"})
            } else if (!APIReference.current && !(location?.state?.isSearchResult || location?.pathname?.includes("/zoeken/"))) {
                const options = {
                    isLoader: true,
                    loaderAction: (bool) => setLoading(bool),
                    setGetResponseData: (res) => {
                        setCheckUrlType(res?.data?.[0]);
                        if(res?.data?.[0]?.message === "Given url doesn't exist"){
                            navigate(`/zoeken/zoekterm=${location?.pathname?.slice(1)}`, {
                                state: {
                                  isSearchResult: true,
                                  value: location?.pathname?.slice(1)
                                }
                              });
                        }
                    },
                    axiosData: {
                        url: `${defaultURL}/page/typecheck?storeId=${storeId}&targetPath=${location?.pathname?.slice(1)}`,
                       
                    },
                };
                APIReference.current = true;
                if (location?.state?.urlType?.entityType) {
                    setCheckUrlType(location?.state?.urlType)
                } else {
                    APIQueryGet(options);
                }
                setTimeout(() => APIReference.current = false, 300);
            }
            prevPathNameRef.current = location.pathname;
        }else{
            setCheckUrlType(location?.state?.typeCheckValue);
            prevPathNameRef.current = location.pathname;
        }
    }, [location?.pathname])
  
    return (
        <div className='plp_products__container py-4 relative'>
            {
                loading ?
                    <>
                    </>
                    : prevPathNameRef.current === location.pathname || location?.pathname?.includes("/zoeken/")?
                    (checkUrlType?.entityType === "category" && checkUrlType?.level >= "3" || location?.state?.isSearchResult || location?.pathname?.includes("/zoeken/") || (checkUrlType?.isChildExist === 0)) ?
                    <SubCategories /> : checkUrlType?.entityType === "category" && checkUrlType?.level === "2" || (checkUrlType?.isChildExist === 1) ?
                        <MainCategories /> : checkUrlType?.entityType === "product" ?
                            <ProductDetails /> : <></>
                    :<></>
            }
        </div>
    )
}

export default memo(ProductPage);