import React, { useEffect, useRef, useState, useContext } from "react";
import DomainContext from "Context/DomainContext";
import "./styles.scss";
import { APIQueryGet, APIQueryPost } from "APIMethods/API";
import { useLocation, useNavigate } from "react-router-dom";
import { useWindowSize } from "Utilities";
import Breadcrumb from "Components/Breadcrumb";
import GalleryVariant from "./Gallery";
import CopyUrlSidebar from "Components/Product/ProductDetails/CopyUrlSidebar";
import AdditionalData from "./AdditionalData";
import ColorPicker from "./ColorPicker";
import ReviewForm from "./ReviewForm";
import AllReviews from "./AllReviews";
import VariantLoader from "./Variant/loader";
import TitleLoader from "./Title/loader";
import GalleryLoader from "./Gallery/loader";
import UrlGeneratorPdp from 'Components/Hooks/UrlGeneratorPdp';
import ProductSlider from "Components/ProductSlider";
import Seo from "Components/Seo/Seo";
import { useDispatch, useSelector } from "react-redux";
import { ACTION__PDP__STATIC__DATA, ACTION_GET__URLTYPE, ACTION__CMS__COLOR } from "Store/action";
import TagManager from 'react-gtm-module';

import Img from "Components/Img";
import { handleAddUser } from "db";
import VisibleWarp from "Context/VisibleWrapper";
const ProductVariant = React.lazy(() => import("./Variant"));
const ProductTitle = React.lazy(() => import("./Title"));

const ProductDetails = () => {
  const dispatch = useDispatch();
  const { defaultURL, storeId } = useContext(DomainContext);
  const getHeaderData = useSelector(state => state?.getHeaderFooterData?.data?.header?.contactSection?.contactDetails);
  const email = getHeaderData?.filter(item => item?.contactInfo?.mailId);
  const mobileNumber = getHeaderData?.filter(item => item?.contactInfo?.whatsappNumber);
  const location = useLocation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const getFooterData = useSelector(state => state?.getHeaderFooterData?.data?.footer?.[0]);
  const recentProductSku = useSelector((state) => state?.recentProductSku);
  const cmsColor = useSelector((state) => state?.cmsColor);
 
  const productDetailsStaticData = useSelector((state) => state?.productDetailsStaticData);
  const recentProducts = useSelector(state => state?.recentProducts);
  const [stateToken, setStateToken] = useState(0);
  const [stateUploadedPath, setStateUploadedPath] = useState(null);
  const [loading, setLoading] = useState(true);
  const [productDetailsData, setProductDetailsData] = useState({});
  const [alternateProductsData, setAlternateProductsData] = useState([]);
  const APIReference = useRef(false);
  const [openCopyURLModel, setOpenCopyURLModel] = useState(false);
  const [galleryData, setGalleryData] = useState({});
  const [gallerySelected, setGallerySelected] = useState([]);
  const [openColorModel, setOpenColorModel] = useState(false);
  const [width] = useWindowSize();
  const [openReview, setOpenReview] = useState(false);
  const [openAllReviews, setOpenAllReviews] = useState(false);
  const [minPrice, setMinPrice] = useState({ qty: 1, price: 0 });
  const { urlGenData, generateNewUrl } = UrlGeneratorPdp();
  // open accordion
  const [accordionView, setAccrodionView] = useState("Productdetails");

  useEffect(() => {
    let tempGalleryData = [];
    const prod = productDetailsData?.settings;
    if (prod?.product_gallery_images && prod.product_gallery_images.length)
      tempGalleryData = [...prod.product_gallery_images];
    if (gallerySelected?.length) tempGalleryData = [...gallerySelected, ...tempGalleryData];
    setGalleryData({
      image: [...tempGalleryData],
      product_name: prod?.product_name,
      bg: prod?.labelBackgroundColor,
      color: prod?.labelColor,
      label: prod?.labelText,
    });
  }, [productDetailsData, gallerySelected]);
  useEffect(() => {
    setLoading(true)
    
    if (!APIReference.current) {
      const options = {
        isLoader: true,
        loaderAction: (bool) => setLoading(bool),
        axiosData: {
          url: `${defaultURL}/pdp${location?.pathname}/0`,
        },
        setGetResponseData: (res) => {
          dispatch(ACTION_GET__URLTYPE("pdp"));
          const data = res?.data[0];
          const sku = data?.settings?.product_sku;
          handleAddUser(dispatch, sku, recentProductSku);
         
          setProductDetailsData(data);
        },
      };
      const staticDatas = {
        isLoader: true,
        axiosData: {
          url: `${defaultURL}/static/pdpStaticDatas`,
        },
        setGetResponseData: (res) => {
          dispatch(ACTION__PDP__STATIC__DATA(res?.data))
        },
      };
      const translatorData = {
        isLoader: true,
        axiosData: {
          url: `${defaultURL}/static/translationPmsColor`,
        },
        setGetResponseData: (res) => {
          dispatch(ACTION__CMS__COLOR(res?.data));
        },
      };

      APIQueryGet(options);

      if (!cmsColor?.length) {
        APIQueryGet(translatorData);
      }
      if (!productDetailsStaticData?.length) {
        APIQueryGet(staticDatas);
      }
      APIReference.current = true;
      setTimeout(() => (APIReference.current = false), 300);
    }
  }, [location?.pathname]);
  const getalternateProducts = {
    isLoader: true,
    axiosData: {
      url: `${defaultURL}/getalternateproducts`,
      paramsData: {
        categoryUrl: productDetailsData?.settings?.breadcrumbs[2]?.urlKey
      }
    },
    setGetResponseData: (res) => {
      setAlternateProductsData(res?.data)
    },
  };
  useEffect(() => {
    if (productDetailsData?.settings?.stock_status == false) {
      APIQueryPost(getalternateProducts)
    }
  }, [productDetailsData])

  useEffect(() => {
    if (loading) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
      setGallerySelected([])
    }
  }, [loading]);
  useEffect(() => {
    if (stateToken === 0 && state?.uploadData?.length) {
      setStateUploadedPath([...state.uploadData]);
      navigate(location.pathname + location.search, { replace: true });
    }
  }, [stateToken, state?.uploadData]);
  const ProductDetailPageGtm = () => {
    let ProductDetailPageGtmData = {
      dataLayer: {
        event: 'Product_Detail_Page',
      }
    }
    TagManager.dataLayer(ProductDetailPageGtmData);
    console.log('GTM_EVENT Product_Detail_Page', ProductDetailPageGtmData);
  };

  useEffect(() => {
    console.log({ productDetailsData }) // For UAT see the PDP page
  }, [productDetailsData]);


  useEffect(() => {
    setStateToken((v) => v + 1);
    ProductDetailPageGtm();
  }, []);

  return (
    <React.Fragment>
      <Seo
        metaTitle={productDetailsData?.seo?.metaTitle}
        metaDescription={productDetailsData?.seo?.metaDescription}
        metaKeywords={productDetailsData?.seo?.metaKeywords}
        ogImage={productDetailsData?.settings?.product_gallery_images?.[0]?.image}
        ogWebSite="Product"
        productPrice={productDetailsData?.settings?.additional_info?.total_price?.substring(2)}
        currency={productDetailsData?.settings?.additional_info?.total_price?.slice(0, 1)}
      />
      {
        productDetailsData?.settings?.stock_status === false && !loading ?
          <div className="outof__stock">
            <div className="flex col gap-5 lg-flex lg-gap-12">
              <div className="flex col container">
                <Breadcrumb
                  type="productDetails"
                  loading={loading}
                  data={productDetailsData?.settings?.breadcrumbs}
                />
                <div className="flex col lg-flex lg-row gap-10 px-4 pt-5 lg-pt-7">
                  <div className="flex image r-5 relative p-5">
                    <div className="flex center middle">
                      <Img src={productDetailsData?.settings?.product_gallery_images?.[0]?.image} alt={productDetailsData?.settings?.product_name} className="image-contain" />
                    </div>
                    {
                      productDetailsData?.settings?.tag?.name ?
                        <div className="tag absolute left-3 r-4 flex middle center">
                          <p
                            className="fs-14 fw-700"
                            style={{
                              background: `${productDetailsData?.settings?.tag?.backgroundColor}`,
                              color: `${productDetailsData?.settings?.tag?.color}`,
                            }}
                          >{productDetailsData?.settings?.tag?.name}</p>
                        </div>
                        : <></>
                    }
                  </div>
                  <div className="info flex-1 flex col gap-10 lg-flex lg-gap-12">
                    {width < 1025 ?
                      <ProductTitle
                        loading={loading}
                        data={productDetailsData?.settings}
                        setOpenReview={setOpenReview}
                        setOpenAllReviews={setOpenAllReviews}
                        setAccrodionView={setAccrodionView}
                      />
                      : <ProductTitle
                        loading={loading}
                        data={productDetailsData?.settings}
                        setOpenReview={setOpenReview}
                        setOpenAllReviews={setOpenAllReviews}
                        minPrice={minPrice}
                        setAccrodionView={setAccrodionView}
                      />
                    }
                    <div className="static flex col">
                      <h3 className="fs-22 line-6 fw-700 pb-1">Het artikel is niet meer leverbaar.</h3>
                      {
                        productDetailsData?.settings?.alternative?.length ?
                          <p className="fs-15 line-6">Bekijk hieronder onze aanbevolen alternatieven.</p>
                          :
                          <>
                            <p className="fs-15 line-6">Neem contact met ons op, wij helpen u graag met het zoeken naar een passend alternatief!</p>
                            <p className="fs-15 line-6">E-mail naar <a href={`mailto: ${email?.[0]?.contactInfo?.mailId}`}>{email?.[0]?.contactInfo?.mailId}</a> of bel <a href={`tel:${mobileNumber?.[0]?.contactInfo?.whatsappLinkNumber}`}>{mobileNumber?.[0]?.contactInfo?.whatsappNumber}</a>.</p>
                          </>
                      }
                    </div>
                  </div>
                </div>
              </div>
              <VisibleWarp>
              {
                alternateProductsData?.length ?
                  <div className="sub__recent__products">
                    <ProductSlider
                      title="Alternatieven"
                      data={alternateProductsData}
                      showToGo={false}
                      loading={false}
                      pageName="pdp"
                    />
                  </div>
                  : <></>
              }
              </VisibleWarp>
            </div>
            {/* Additional data */}
            <div className="flex col container">
              <div className="addtional__data__container container px-4">
                <AdditionalData
                  loading={loading}
                  settingsData={productDetailsData?.settings}
                  mondu={productDetailsStaticData[0]?.mondu?.[0]}
                  reviews={productDetailsStaticData[0]?.kiyoh_reviews}
                  getInfo={productDetailsData?.settings?.staticContents}
                  setOpenReview={setOpenReview}
                  setOpenAllReviews={setOpenAllReviews}
                  accordionView={accordionView}
                  setAccrodionView={setAccrodionView}
                  productDetailsStaticData={productDetailsStaticData[0]}
                  cmsColor={cmsColor[0]}
                />
              </div>
            </div>

          </div>
          :
          <div className="container">
            <Breadcrumb
              type="productDetails"
              loading={loading}
              data={productDetailsData?.settings?.breadcrumbs}
            />
            <div className="px-4 pt-1 lg-pt-7">
              <div className="productDetails flex gap-9 col xxl-flex xxl-row xxl-gap-12 w-1/1">
                <div className="flex-1 flex col gap-6 xxl-w-1/2">
                  {width < 1025 ? loading ? <TitleLoader /> : (
                    <ProductTitle
                      loading={loading}
                      data={productDetailsData?.settings}
                      setOpenReview={setOpenReview}
                      setOpenAllReviews={setOpenAllReviews}
                      setAccrodionView={setAccrodionView}
                    />
                  ) : (
                    <></>
                  )}
                  {loading ? <GalleryLoader /> : <GalleryVariant loading={loading} imgData={galleryData} alt={productDetailsData?.settings?.product_name} />}
                </div>
                <div className="flex-1 flex col gap-6 xxl-w-1/2">
                  {width >= 1025 ? loading ? <TitleLoader /> : (
                    <ProductTitle
                      loading={loading}
                      data={productDetailsData?.settings}
                      setOpenReview={setOpenReview}
                      setOpenAllReviews={setOpenAllReviews}
                      minPrice={minPrice}
                      setAccrodionView={setAccrodionView}
                    />
                  ) : (
                    <></>
                  )}
                  {loading ?
                    <VariantLoader /> :
                    <ProductVariant
                      data={productDetailsData}
                      setGallerySelected={setGallerySelected}
                      setOpenCopyURLModel={setOpenCopyURLModel}
                      urlGenData={urlGenData}
                      generateNewUrl={generateNewUrl}
                      setMinPrice={setMinPrice}
                      stateUploadedPath={stateUploadedPath}
                      setStateUploadedPath={setStateUploadedPath}
                      productDetailsStaticData={productDetailsStaticData[0]}
                      cmsColor={cmsColor[0]}
                    />
                  }
                </div>
              </div>

              {/* Additional data */}
              <div className="addtional__data__container">
                <AdditionalData
                  loading={loading}
                  settingsData={productDetailsData?.settings}
                  mondu={productDetailsStaticData[0]?.mondu?.[0]}
                  reviews={productDetailsStaticData[0]?.kiyoh_reviews}
                  getInfo={productDetailsData?.settings?.staticContents}
                  setOpenReview={setOpenReview}
                  setOpenAllReviews={setOpenAllReviews}
                  accordionView={accordionView}
                  setAccrodionView={setAccrodionView}
                  productDetailsStaticData={productDetailsStaticData[0]}
                  cmsColor={cmsColor[0]}
                />
              </div>
              {/* copy URL sidebar */}
              <CopyUrlSidebar
                openCopyURLModel={openCopyURLModel}
                setOpenCopyURLModel={setOpenCopyURLModel}
                productName={productDetailsData?.settings?.product_name}
              />
              <ColorPicker
                openColorModel={openColorModel}
                setOpenColorModel={setOpenColorModel}
              />
            </div>
          </div>
      }
      <VisibleWarp>
      {
        recentProducts?.length ?
          <div className="sub__recent__products">
            <ProductSlider
              title="Recent bekeken"
              data={recentProducts}
              showToGo={false}
              loading={false}
              pageName="pdp"
            />
          </div>
          : <></>
      }
      </VisibleWarp>
      <VisibleWarp>
      {
        loading && !recentProducts?.length ?
          <div className="sub__recent__products">
            <ProductSlider
              title="Recent bekeken"
              data={[]}
              showToGo={false}
              loading={loading}
              pageName="pdp"
            />
          </div>
          : <></>
      }
      </VisibleWarp>

      <ReviewForm openModel={openReview} setOpenModel={setOpenReview} product_id={productDetailsData?.settings?.product_id} productTitle={productDetailsData?.settings?.product_name} />
      <AllReviews openModel={openAllReviews} setOpenModel={setOpenAllReviews} data={productDetailsData?.settings?.review_details?.details} />
      </React.Fragment>
  );
};
export default ProductDetails;
