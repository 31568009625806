
import { Routes, Route } from "react-router-dom";
import ContactUs from "./Pages/ContactUs";
import Home from "./Pages/Home";
import ProductPage from "Pages/ProductPage";
import Registration from "Pages/Authencation/Registration";
import ResetPassword from "Pages/Authencation/ResetPassword";
import MyAccount from "Components/MyAccount";
import CartPage from "Pages/CartPage";
import QuotePage from "Pages/QuotePage";
import QuoteConfirmation from "Pages/QuoteConfirmation";
import Checkout from "Pages/Checkout";
import OrderConfirmation from "Components/Checkout/OrderConfirmation";
import Disclaimer from "Pages/Disclaimer";
import AboutUs from "./Pages/Aboutus";
import PrintingTechniques from "./Pages/PrintingTechniques";
import CustomerService from "./Pages/CMS/CustomerService";
import PrivacyPolicy from "Pages/CMS/PrivacyPolicy";
import TermsConditions from "Pages/CMS/TermsConditions";
import VacanciesDashboard from "Pages/CMS/Vacancies/Dashboard";
import ViewJobDetails from "Pages/CMS/Vacancies/ViewJobDetails";
import Shoroom from "Pages/CMS/Shoroom";
import Faq from "Pages/Faq";
import FaqDetail from "Pages/FaqDetail";
import KortingsCodes from "Pages/KortingsCodes";
import BlogListing from "Pages/CMS/Blog/BlogListing";
import BlogDetails from "Pages/CMS/Blog/BlogDetails";
import ReviewListing from "Pages/ReviewListing";
import NoRoute from "Components/NoRoute/NoRoute";
import SiteMap from "Pages/SiteMap";
const AppRoutes = () => {
 
return (
    <Routes>
      <Route path="/" element={<Home />} exact />
      <Route
        path="/maak-account-aan"
        element={<Registration />}
        exact
      />
      <Route
        path="/wachtwoord-opnieuw-instellen/:token"
        element={<ResetPassword />}
        exact
      />
      <Route path="/*" element={<ProductPage />} exact />
      <Route path="/winkelwagen" element={<CartPage />} exact />
      <Route path="/offerteaanvraag" element={<QuotePage />} exact />
      <Route path="/meest-gestelde-vragen" element={<Faq />} exact />
       <Route path="/meest-gestelde-vragen/*" element={<FaqDetail />} exact />

     
      <Route
        path="/offerteaanvraag/succes"
        element={<QuoteConfirmation />}
        exact
      />
      <Route
        path="/order/succes"
        element={<OrderConfirmation />}
        exact
      />
      <Route path="/pagina-niet-gevonden" element={<NoRoute />} exact />
      <Route path="/kiyoh" element={<ReviewListing />} exact />
      <Route path="/checkout" element={<Checkout />} exact />
      <Route path="/privacy" element={<PrivacyPolicy />} exact />
      <Route path="/algemene-voorwaarden" element={<TermsConditions />} exact />
      <Route path="/showroom" element={<Shoroom />} exact />
      <Route path="/vacatures" element={<VacanciesDashboard />} exact />
      <Route path="/vacatures/:key" element={<ViewJobDetails />} exact />
      <Route path="/blog" element={<BlogListing />} exact />
      <Route path="/blog/:key" element={<BlogDetails />} exact />
      <Route path="/contact" element={<ContactUs />} exact />
      <Route path="/disclaimer" element={<Disclaimer />} exact />
      <Route path="/over-ons" element={<AboutUs />} exact />
      <Route path="/druktechnieken" element={<PrintingTechniques />} exact />
      <Route path="/klantenservice" element={<CustomerService />} exact />
      <Route path="/klantenservice/*" element={<CustomerService />} exact />
      <Route path="/mijn-account/:key" element={<MyAccount />} exact />
      <Route path="/mijn-account/:key/:key" element={<MyAccount />} exact />
      <Route path="/kortingscodes"  element={<KortingsCodes />} />
      <Route path="/sitemap"  element={<SiteMap />} />
    </Routes>
  );
};

export default AppRoutes;