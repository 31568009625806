const LOADER = "LOADER";
const FETCH__HOMEPAGE = "FETCH__HOMEPAGE";
const FETCH__HOMEPAGE_FEATURE_PRODUCT ="FETCH__HOMEPAGE_FEATURE_PRODUCT";
const HOMEPAGE__LOADING = "HOMEPAGE__LOADING";
const FETCH__HEADERFOOTER = "FETCH__HEADERFOOTER";
const HEADERFOOTER__LOADING = "HEADERFOOTER__LOADING";
const ISLOGGEDUSER = "ISLOGGEDUSER";
const CUSTOMER__QUOTE__ID = "CUSTOMER__QUOTE__ID";
const CUSTOMER__TOKEN = "CUSTOMER__TOKEN";
const CUSTOMER__DETAILS = "CUSTOMER__DETAILS";
const WISHLIST__DATA = "WISHLIST__DATA";
const UPDATE__WISHLIST = "UPDATE__WISHLIST";
const OPEN__LOGIN = "OPEN__LOGIN";
const OPEN__FORGOTPASSWORD ="OPEN__FORGOTPASSWORD";
const WISHLISTPRODUCTID = "WISHLISTPRODUCTID";
const GUESTKEY = "GUESTKEY";
const GUESTQUOTE__DETAILS = "GUESTQUOTE__DETAILS";
const CARTITEMS = "CARTITEMS";
const UPDATE__CARTITEMS = "UPDATE__CARTITEMS";
const OPENCART = "OPENCART";
const COUNTRYLIST = "COUNTRYLIST";
const WISHLISTPAGINTIONCOUNT = "WISHLISTPAGINTIONCOUNT";
const WISHLISTLOADER = "WISHLISTLOADER";
const WISHLISTDEFAULTCOUNT = "WISHLISTDEFAULTCOUNT";
const ADMINTOKEN = "ADMINTOKEN";
const SAVE_ADDRESS = 'SAVE_ADDRESS';
const SAVE_ADDRESS_SHIPPING="SAVE_ADDRESS_SHIPPING";
const SELECTEDADDRESS_BILLING="SELECTEDADDRESS_BILLING";
const SELECTEDADDRESS_SHIPPING="SELECTEDADDRESS_SHIPPING";
const SESSION_EXPIRY = "SESSION_EXPIRY"; 
const MINICART__ITEMS ="MINICART__ITEMS";
const WISHLISTADDED__DATA = "WISHLISTADDED__DATA";
const WISHLIST_COUNT = "WISHLIST_COUNT";
const RECENT_VIEW_SKU = "RECENT_VIEW_SKU";
const RECENT_VIEW = "RECENT_VIEW";
const RECENT_VIEW_LOAD = "RECENT_VIEW_LOAD";
const TOAST = "TOAST";
const COOKIE_VALUE = "COOKIE_VALUE";
const GET__URLTYPE = "GET__URLTYPE";
const CMS__COLOR = "CMS__COLOR";
const  PDP__STATIC__DATA= "PDP__STATIC__DATA";
export {
    LOADER, FETCH__HOMEPAGE, HOMEPAGE__LOADING,CMS__COLOR, 
    FETCH__HEADERFOOTER, HEADERFOOTER__LOADING, ISLOGGEDUSER,
    CUSTOMER__QUOTE__ID, CUSTOMER__TOKEN, CUSTOMER__DETAILS,
    WISHLIST__DATA, UPDATE__WISHLIST, OPEN__LOGIN, WISHLISTPRODUCTID,
    GUESTKEY, GUESTQUOTE__DETAILS, CARTITEMS, UPDATE__CARTITEMS,OPEN__FORGOTPASSWORD,
    OPENCART, COUNTRYLIST, WISHLISTPAGINTIONCOUNT, WISHLISTLOADER, WISHLISTDEFAULTCOUNT,
    ADMINTOKEN,SAVE_ADDRESS,SAVE_ADDRESS_SHIPPING, SESSION_EXPIRY,MINICART__ITEMS,
    WISHLISTADDED__DATA, WISHLIST_COUNT, RECENT_VIEW, RECENT_VIEW_SKU, RECENT_VIEW_LOAD,
    TOAST,COOKIE_VALUE, GET__URLTYPE,PDP__STATIC__DATA,SELECTEDADDRESS_SHIPPING,SELECTEDADDRESS_BILLING,FETCH__HOMEPAGE_FEATURE_PRODUCT
};