import React, { useEffect, useMemo, useRef, useState } from "react";
import "./styles.scss";
import Img from "Components/Img";
import { Zoom } from '@mui/material';
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useWindowSize } from "Utilities";

const extractYouTubeVideoId = (imageData) => {
  const regex = /[?&]?(?:v=|watch\/|embed\/|youtu.be\/)([a-zA-Z0-9_-]{11})/;
  const match = imageData?.match(regex);
  return match && match[1];
};
const handleImage = (image_url) => {
  if (!image_url) return null;
  let image_url_split;
  try {
    image_url_split = new URL(image_url);
  } catch (e) {
    return image_url;
  }
if(image_url_split?.pathname){
  const combine = process.env.REACT_APP_PRODUCT_CDN_URL 
    ? image_url_split && image_url_split?.pathname ? `${process.env.REACT_APP_PRODUCT_CDN_URL}${image_url_split.pathname}` 
    : image_url : image_url;

  const validExtensions = ['jpeg', 'jpg', 'png', 'gif', 'bmp', 'jp2'];
  const urlExtension = combine.split('.').pop().toLowerCase();

  return validExtensions.includes(urlExtension)
    ? combine.replace(/\.(jpg|jpeg|png)$/i, '.webp')
    : combine;
}else{
  return image_url
}
};
const RenderVideo = ({ videoData }) => {
  const videoId = extractYouTubeVideoId(videoData);
  return (
    <div className="iframe-container">
      <div className="youtube-video-container">
        <iframe
          title="YouTube Video"
          src={`https://www.youtube.com/embed/${videoId}`}
            allowFullScreen
            allow="encrypted-media"
          style={{
            position:"absolute",
            width: "100%",
            height: "100%",
            top:0,
            left:0,
            zIndex: 10000,
          }}
        ></iframe>
      </div>
    </div>
  );

};
const GallerySliderImg = ({imageData, index}) => {
  const isYouTubeUrlMemo =useMemo(() => {
      return imageData?.image?.match(/^(https?\:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/);
  },[imageData?.image]);
  return (
    <div className={`keen-slider__slide number-slide${index + 1}`}>
      <div className={`h-1/1 w-1/1 relative`}>
        {isYouTubeUrlMemo ? (
          <RenderVideo videoData={imageData?.image} />
        ) : (
          <>
            <span className="overlay"></span>
            <Img webp={false} alt="img" type="img" src={handleImage(imageData?.image)} style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "contain" }} />
          </>
        )}
        <div style={{ paddingTop: "100%" }}></div>
      </div>
    </div>
  )
}
const GallerySlider = ({ data, active, open }) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [loaded, setLoaded] = useState(false)
  const [sliderRef, instanceRef] = useKeenSlider({
    loop: true,
    initial: 0,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
    },
    created() {
      setLoaded(true)
    },
  })

  return data?.length ? (
    <div className="GallerySliderPDP fixed w-1/1 h-1/1 top-0 left-0 zindex-11" style={{ background: "white" }}>
      <div className="navigation-wrapper relative">
        <div ref={sliderRef} className="content keen-slider" style={{ height: "100%" }}>
          {data?.map((imageData, index) => (
            <GallerySliderImg key={`keen-slider__slide_number-slide${index + 1}`} imageData={imageData} index={index}  />
          ))}
        </div>
      </div>
      <span className="overlay"></span>
      {loaded && instanceRef.current && (
        <div className="absolute w-1/1 left-0" style={{ bottom: "40px", zIndex: 3 }}>
          <div className="dots flex w-1/1 center middle">
            {[
              ...Array(instanceRef.current.track.details.slides.length).keys(),
            ].map((idx, idex) => {
              return (
                <button
                  style={{ width: 12, height: 12, borderRadius: "8px", margin: 6, background: "#c5c5c5" }}
                  key={`dots__${idx}`}
                  onClick={() => {
                    instanceRef.current?.moveToIdx(idx)
                  }}
                  className={"dot" + (currentSlide === idx ? " active" : "")}
                ></button>
              )
            })}
          </div>
        </div>
      )}
      <IconButton onClick={() => open(false)} aria-label="close">
        <CloseIcon />
      </IconButton>
    </div>
  ) : <></>;
}
const GalleryPopupItem = ({ id, setId, data, isShow }) => {
  const imgRef = useRef(null);
  const isYouTubeUrl = (url) => {
    return url.match(/^(https?\:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/);
  };

  const RenderMediaImage = ({ imageData }) => {
    const videoId = extractYouTubeVideoId(imageData);
    return (
      <div className="">
        <span className="overlay"></span>
        <Img alt="img" type="img" ref={imgRef}
          src={`https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`}
          style={{ maxWidth: "100%", maxHeight: "100%" }} />

      </div>
    );

  };
  return isShow && (
    <div className="content keen-scroller flex col">
      {/* <div ref={sliderRef} className="content keen-slider" style={{ height: "100%", paddingTop:80 }}> */}
      {isShow && data?.map((imageData, index) => (
        <div key={`keen-slider__slide number-slide${index + 1}`} className={`keen-slider__slide number-slide${index + 1}`}>
          <button className={`h-1/1 w-1/1 relative ${id === index}`} onClick={() => setId(index)}>
            {isYouTubeUrl(imageData?.image) ? (
              <>
                <div className="play-button"
                ></div>
                <RenderMediaImage imageData={imageData?.image} />
              </>
            ) : (
              <>
                <span className="overlay"></span>
                <Img alt="img" type="img" ref={imgRef} src={handleImage(imageData?.image)} style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "contain" }} />

              </>
            )}
            <div style={{ paddingTop: "100%" }}></div>
          </button>
        </div>
      ))}
    </div>
  )
}
const GalleryPopup = ({ show, data, active, open }) => {
  const [isZoomIn, setIsZoomIn] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [id, setId] = useState(active ?? 0);
  useEffect(() => {
    let temp = null
    if (show && data?.length) show = setTimeout(() => setIsShow(show), 500)
    else setIsShow(false)
    return () => {
      temp && clearTimeout(temp)
    }
  }, [show, data?.length])

  const isYouTubeUrlMemo =useMemo(() => {
    return data[id]?.image?.match(/^(https?\:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/);
},[data[id]?.image]);

  return show && (
    <Zoom in={data?.length}>
      <div className="popupGallery fixed w-1/1 h-1/1 top-0 left-0 zindex-11" style={{ background: "white" }}>
        <div className="flex w-1/1 h-1/1 overflow-hidden absolute">
          <div className={`sidebar px-6 h-1/1 ${isZoomIn}`}>
            <div className={`sidebarContainer`}>
              <div className={`sidebarContent`}>
                <GalleryPopupItem id={id} setId={setId} data={data} isShow={isShow} />
              </div>
            </div>
          </div>
          <div className="flex-1 h-1/1 flex relative">
            <button className={`fullImage absolute w-1/1 h-1/1 top-0 left-0 ${isZoomIn}`} onClick={() => setIsZoomIn(!isZoomIn)} >

              {isYouTubeUrlMemo ? (
                <RenderVideo videoData={data[id]?.image} />

              ) : (
                <>
                  <button className="overlay" ></button>
                  <div className="images w-1/1 h-1/1">
                    <Img
                      type="img"
                      animation={false}
                      src={handleImage(data[id]?.image)}
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        objectFit: "contain",
                      }}
                      webp={false}
                    />
                  </div>
                </>

              )}

            </button>
          </div>
        </div>
        <IconButton onClick={() => open(false)} aria-label="close">
          <CloseIcon />
        </IconButton>
      </div>
    </Zoom>
  )
}
const GalleryVariant = ({ imgData, alt }) => {
  const [initRender, setInitRender] = useState(false);
  const [width] = useWindowSize();
  const random = Math.round(Math.random() * 1000);
  const [selectedImage, setSelectedImage] = useState(null);
  const [id, setId] = useState(0);
  const [data, setData] = useState(0);
  const [showAll, setShowAll] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const imgRef1 = useRef(null);

  const isYouTubeUrlMemo =useMemo(() => {
      return selectedImage?.match(/^(https?\:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/);
  },[selectedImage])

  const handleThumbnailClick = (image) => {
    setSelectedImage(image);
  };
  const isYouTubeUrl = (url) => {
    return url?.match(/^(https?\:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/);
  };
  const RenderMediaImage = ({ imageData }) => {
    const videoId = extractYouTubeVideoId(imageData);
    return (
      <div className="" style={{ height: "100%" }}>
        <span className="overlay"></span>
        <Img alt="img" type="img" ref={imgRef1}
          src={`https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`}
          style={{ maxWidth: "100%", maxHeight: "100%" }} />

      </div>
    );
  };
  useEffect(() => {
    if (data && data.length > 0) {
      setId(0);
      setSelectedImage(data[0]?.image);
    }
  }, [data]);
  useEffect(() => {
    if (imgData?.image?.length) {
      setData([...imgData?.image])
    }
  }, [imgData]);
  useEffect(() => {
    const htmlTag = document.getElementsByTagName("html").item(0);
    if (initRender) {
      if (showPopup) htmlTag.classList.add(`ModelPopup-${random}`);
      else htmlTag.classList.remove(`ModelPopup-${random}`);
    }
    return () => htmlTag.classList.remove(`ModelPopup-${random}`);
  }, [showPopup]);
  useEffect(() => {
    if (!initRender) setInitRender(true)
  }, [initRender]);
  
  return (
    <div className="relative w-1/1">
      <div className="galleryVariant">
        <React.Fragment>
          <div onClick={() => setShowPopup(true)} className=" zindex-1 imgContainer relative flex center middle mb-5 p-10">
            <button className="overlay"></button>
            {imgData?.label ? (
              <div className="tag__name absolute flex gap-y-2 top-2 col left-3 zindex-2">
                <p
                  className="r-4 "
                  style={{
                    maxHeight: 32,
                    background: imgData?.bg ?? "#FFFFFF",
                    color: imgData?.color ?? "var(--themeColor)",
                  }}
                >
                  {imgData?.label}
                </p>
              </div>
            ) : <></>}

            {isYouTubeUrlMemo ? (
                <RenderVideo videoData={selectedImage}  />
            ):(
              <button className="absolute w-1/1 h-1/1 top-0 left-0 pointer" onClick={() => setShowPopup(true)}>
                <Img
                  type="img"
                  animation={false}
                  src={handleImage(selectedImage)}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    objectFit: "contain",
                  }}
                  webp={false}
                  alt={alt}
                />
              </button>
            )}

            <div style={{ paddingTop: "100%" }}></div>
          </div>
          <div className="img__thumbnailContainer container relative">
            {showAll ? (
              <>
                {data?.length &&
                  data?.map((imageData, index) => (
                    <div className="thumb__img" key={`data_img__thumbnailContainer_${index}`}>
                      <div className="image__wrapper relative"
                        key={`image__wrapper${index}`}
                        onClick={() => {
                          handleThumbnailClick(imageData?.image);
                          setId(index)
                        }}
                      >
                        <div className="absolute w-1/1 h-1/1 top-0 left-0">
                          <Img src={handleImage(imageData?.image)} style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "contain" }} />
                        </div>
                        <div style={{ paddingTop: "100%" }}></div>
                      </div>
                    </div>
                  ))}
              </>
            ) : (
              <>
                {data?.length &&
                  data?.slice(0, 5)?.map((imageData, index) => (
                    <div className="thumb__img" key={`thumb__img__${index}`}>
                      {index === 4 && data?.length > 5 && !showAll ? (
                        <div
                          className="image__wrapper relative p-2 relative w-1/1 h-1/1"
                          key={index}
                          onClick={() => {
                            setShowPopup(true);
                          }}
                        >
                          <div className="image__inner absolute w-1/1 h-1/1 top-0 left-0 flex center middle">
                            <p className="w-1/1 h-1/1 fs-20 sm-fs-32">{`+${data?.length - 4}`}</p>
                           </div>
                        </div>
                      ) : (
                        <div
                          className={`image__wrapper  relative ${index == id ? "hover" : ""}`}
                          key={index}
                          onClick={() => { handleThumbnailClick(imageData?.image); setId(index) }}
                        >

                          {isYouTubeUrl(imageData?.image) ? (
                            <>
                              <div className="play-button"
                              ></div>
                              <RenderMediaImage imageData={imageData?.image} />
                            </>
                          ) : (
                            <>
                              <div className="image__inner absolute w-1/1 h-1/1 top-0 left-0 flex center middle">
                                <Img
                                  src={handleImage(imageData?.image)}
                                  alt={`Thumbnail ${index + 1}`}
                                  style={{
                                    maxWidth: "100%",
                                    maxHeight: "100%",
                                    objectFit: "contain",
                                  }}
                                />
                              </div>
                              <div style={{ paddingTop: "100%" }}></div>
                            </>
                          )}

                        </div>
                      )}
                    </div>
                  ))}

              </>
            )}
          </div>
          {showPopup ? (width > 768 ?
            <GalleryPopup show={showPopup} data={data} active={id} open={setShowPopup} />
            :
            <GallerySlider show={showPopup} data={data} active={id} open={setShowPopup} />
          ) : <></>}
        </React.Fragment>
      </div>
    </div>
  );
};

export default GalleryVariant;