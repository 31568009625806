import React, { memo, useEffect } from "react";
import "./styles.scss";
import Button from "Components/Common/Button";
import Img from "Components/Img";
import { SkeletonImg, SkeletonLine } from "Components/Skeletion";
import Slider from "react-slick";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

const BannerItem = ({ loading, ...props }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    title,
    description,
    buttonText,
    backgroundColor,
    img,
    button_url,
    link_url,
  } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const handleNavigate = (url) => {
    navigate(url);
  };
  return (
    <div
      className="banner w1/1 md-py-0"
      style={{ background: `${backgroundColor}1a` }}
    >
      <div
        className="container lg-px-4"
        onClick={() => handleNavigate(link_url)}
      >
        <div className="w-1/1 flex col-i gap-2 lg-flex lg-row lg-gap-2 lg-fillY">
          <div className="flex-1">
            <div className="pt-8 pb-16 lg-py-8 px-4 lg-px-0">
              <div className="content-banner flex-1 flex gap-6 col">
                <div className="banner-info flex-1 flex gap-3 col">
                  {loading ? (
                    <>
                      <SkeletonLine height="56px" animation="pulse" />
                      <SkeletonLine height="28px" animation="pulse" />
                    </>
                  ) : (
                    <>
                      <h1 className="ellips line3">{title}</h1>
                      <p className="ellips line3">{description}</p>
                    </>
                  )}
                </div>
                {loading ? (
                  <SkeletonLine
                    height="48px"
                    width="50%"
                    className="r-full"
                    animation="pulse"
                  />
                ) : buttonText ? (
                  <div>
                    <Button size="md" className="r-full">
                      <Link className="fw-700 px-3" to={button_url}>
                        {buttonText}
                      </Link>
                    </Button>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <div
            className={`flex-1 imgContainer ${
              loading ? "block" : "flex"
            } center middle relative`}
            style={{ background: `${backgroundColor}3d` }}
          >
            <div className='maxWidth:"100%", maxHeight:"100%'>
              {loading ? (
                <SkeletonImg
                  className="r-full"
                  animation="pulse"
                  style={{
                    width: "100%",
                    minHeight: "350px",
                    maxHeight: "350px",
                  }}
                />
              ) : img ? (
                <Img
                  src={img}
                  alt={title}
                  type="img"
                  animation={false}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "350px",
                    minHeight: "350px",
                    objectFit: "contained",
                  }}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const BannerContent = () => {
  const homePageLoading = useSelector((state) => state?.homePageLoading);
  const getBannerData = useSelector(
    (state) => state?.getHomePageData?.data?.bannerData?.mainBanner
  );

  const settings = {
    infinite: true,
    dots: false,
    autoplay: true,
    arrows: false,
    initialSlide: 1,
    slidesToShow: 1,
    speed: 400,
    swipeToSlide: true,
  };

  return homePageLoading || !getBannerData?.length ? (
    <div className="slick-nogap">
      <Slider {...settings}>
        {[""].map((__, index) => (
          <BannerItem
            key={`BannerEmptyList_${index}`}
            loading={homePageLoading}
            backgroundColor={"#FF9100"}
          />
        ))}
      </Slider>
    </div>
  ) : getBannerData && getBannerData.length ? (
    <div className="slick-nogap">
      <Slider {...settings}>
        {getBannerData.map((bannerData, index) => (
          <BannerItem
            key={`BannerItem_${index}`}
            img={bannerData?.image_url}
            title={bannerData?.header_title}
            buttonText={bannerData?.button_title}
            description={bannerData?.header_content}
            button_url={bannerData?.button_url}
            link_url={bannerData?.link_url}
            backgroundColor={bannerData?.backgroundColor || "#FF9100"}
          />
        ))}
      </Slider>
    </div>
  ) : (
    <></>
  );
};

export default memo(BannerContent);
