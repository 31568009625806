import React, { useEffect, useState, useContext, useRef } from "react";
import DomainContext from "Context/DomainContext";
import "./styles.scss";
import Breadcrumb from "Components/Breadcrumb";
import CategoriesDescription from "../PlpCommon/CategoriesDescription";
import Categories from "Components/Categories";
import ProductSlider from "Components/ProductSlider";
import MundoRating from "Components/Home/MundoRating";
import Infoblock from "../PlpCommon/InfoBlock";
import FeaturesSection from "../PlpCommon/FeaturesSection";
import { useLocation } from 'react-router-dom';
import { APIQueryGet } from "APIMethods/API";
import { useSelector } from "react-redux";
import useScrollToTop from "Components/Hooks/useScrollToTop";
import Seo from "Components/Seo/Seo";
import TagManager from 'react-gtm-module';
import Ecobanner from "Components/Ecobanner/Ecobanner";

const PlpDescription = () => {
  const recentProducts = useSelector(state => state?.recentProducts);
  const customerDetails = useSelector((state) => state?.customerDetails);
  const { defaultURL, storeId } = useContext(DomainContext);
  const [page, setPage] = useState(0);
  useScrollToTop();
  const [loading, setProductpageLoading] = useState(true);
  const [plpData, setPlpData] = useState({});
  const location = useLocation();
  const APIRef = useRef(false);
  const [isCalled,setIsCalled] = useState(false);
  
  const dataBlock = React.useMemo(() => {
    if (!plpData?.categoryProducts || !plpData.categoryProducts[0]) {
      return null; // or handle accordingly if data is not available
    }
    return Object.values(plpData.categoryProducts[0]).map((item, index) => (
      <React.Fragment key={`plpData_${item?.title}_${index}`}>
        {item?.data?.length ? (
            <ProductSlider
              title={item?.title}
              subTitle={item?.subTitle}
              showToGo={true}
              data={item?.data}
              toGo={item?.urlKey}
              showAllLastCard
              pageName="plp1"
              index={index}
            />
        ) : (
          ""
        )}
      </React.Fragment>
    ));
  }, [plpData?.categoryProducts, page]);
  
  const optionsFirst = {
    isLoader: true,
    loaderAction: (bool) => setProductpageLoading(bool),
    setGetResponseData: (res) => {
      setIsCalled(true);
      if (!plpData?.categoryProducts?.length && !isCalled ) {
        setPlpData(res?.data?.[0]);
        
      } else if(page === 1){
         setPlpData(prevState => ({
          ...prevState,
          categoryProducts: [{
            ...res?.data[0]?.categoryProducts?.[0]
          }]
        }));
      }else if(res?.data?.[0]?.categoryProducts?.length){
        setPlpData(prevState => ({
          ...prevState,
          categoryProducts: prevState?.categoryProducts?.map((category) => {
            const newCategory = res?.data?.[0]?.categoryProducts?.find(newCat => {
              const key = Object.keys(newCat)[0];
              return newCat[key]?.subTitle === category[key]?.subTitle;
            });
            if (newCategory) {
              const key = Object.keys(newCategory)[0];
              return {
                ...category,
                [key]: {
                  ...category[key],
                  data: [
                    ...category[key]?.data,
                    ...newCategory[key]?.data
                  ]
                }
              };
            }else{
           return  {...prevState?.categoryProducts?.[0],...res?.data?.[0]?.categoryProducts?.[0]};
            }
          })
        }));
      }
      if (res?.data?.[0]?.page_size !== page) {
        setPage(page + 1);
      }
    },
    axiosData: {
      url: `${defaultURL}/plp/categoryview?data[catUrl]=${location?.pathname?.slice(1)}&data[customerId]=${customerDetails?.id ? customerDetails?.id : 0}&data[categoryId]=&data[storeId]=${storeId}&data[page]=${page}`,
     
    },
  };


  const ProductListingPage1Gtm = () => {
    let ProductListingPage1GtmData = {
      dataLayer: {
        event: 'Product_Listing_Page_1',
      }
    }
    TagManager.dataLayer(ProductListingPage1GtmData);
  };
  useEffect(() => {
    if (!APIRef.current) {
      APIQueryGet(optionsFirst);
      
      APIRef.current = true;
      setTimeout(() => APIRef.current = false, 200);
    }
  }, [location, APIRef, page]);
  useEffect(() => {
    ProductListingPage1Gtm();
    return () => {
      setPage(0);
      setPlpData({});
      setIsCalled(false);
    };
  }, [location.pathname]);

  return (
    <React.Fragment>
      <Seo
        metaTitle={plpData?.seo?.metaTitle}
        metaDescription={plpData?.seo?.metaDescription}
        metaKeywords={plpData?.seo?.metaKeywords}
        ogWebSite="Category listing"
      />
      <div className="main__categories__container">
        <div className="main__categories__wrapper pb-4">
          <Breadcrumb type="plpParentCategories" data={plpData?.breadCrums} loading={!plpData?.breadCrums?.length ? loading : false} />
          <div className="container">
            <CategoriesDescription data={plpData?.categoryDescription} loading={!plpData?.categoryDescription?.title ? loading : false} />
          </div>
          <div className="plp__categories">
            <Categories
              data={plpData?.chooseCategory?.data}
              title={plpData?.chooseCategory?.title}
              isPLP2
              loading={!plpData?.chooseCategory?.title ? loading : false}
            />
          </div>
          
          <div className="product__reultsddd pt-6 pb-6 lg-pt-5 lg-pb-12">
            <FeaturesSection
              data={plpData?.features}
              loading={!plpData?.features?.length ? loading : false}
              className=" plp2 features__sliders plp1"
              isFeatures={true}
              isPlp1={true}
            />
          </div>
          
          <div>
            {
              plpData?.categoryProducts && plpData?.categoryProducts?.[0] && Object.values(plpData?.categoryProducts?.[0])?.length ?
                dataBlock
                : <></>
            }
            {
              loading ?
                ['', ''].map((item, ind) => (
                  <React.Fragment key={`plp1Productsloader${ind + 1}`}>
                    <ProductSlider
                      title="Laden"
                      data={[]}
                      showToGo={false}
                      pageName="plp1"
                    />
                  </React.Fragment>
                ))
                : <></>}
            {
              loading && !plpData?.promotionMainBanner?.length ?
                <Ecobanner loading={loading} />
                :
                plpData?.promotionMainBanner?.length ? (
                  <Ecobanner
                    loading={false}
                    img={plpData?.promotionMainBanner?.[0]?.image}
                    title={plpData?.promotionMainBanner?.[0]?.headerTitle}
                    buttonText={plpData?.promotionMainBanner?.[0]?.buttonTitle}
                    description={plpData?.promotionMainBanner?.[0]?.headerContent}
                    button_url={plpData?.promotionMainBanner?.[0]?.buttonUrl}
                    backgroundColor={ plpData?.promotionMainBanner?.[0]?.backgroundColor }
                  />
                ) : (
                  <></>
                )}
          </div>
          <div>
            <MundoRating
              loading={!plpData?.breadCrums?.length ? loading : false}
              getReviews={plpData?.reviews}
              getMondu={plpData?.mondu && plpData?.mondu[0]}
            />
          </div>
          {
            !loading && recentProducts?.length ?
              <div className={`${plpData?.staticContents?.[0]?.block ? 'pb-16' : 'pb-0'}`}>
                <ProductSlider
                  title="Recent bekeken"
                  data={recentProducts}
                  showToGo={false}
                  loading={loading}
                  pageName="plp1"
                />
              </div>
              : <></>
          }
          {
            loading && !plpData?.staticContents ?
              <div className="container-fluid plp__infoblock">
                <Infoblock getInfo={plpData?.staticContents} loading={true} />
              </div>
              : plpData?.staticContents?.[0]?.block ? <div className="container-fluid plp__infoblock">
                <Infoblock getInfo={plpData?.staticContents} loading={false} />
              </div> : <></>
          }
        </div>
      </div>
    </React.Fragment>
  );
};

export default PlpDescription;
